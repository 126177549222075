@use "@angular/material" as mat;
@use "styles-variables";
@use "bootstrap/scss/bootstrap-reboot";
@use "bootstrap/scss/bootstrap-grid";
@use "styles-reset" as sr;
@use "styles-reset.scss-theme" as srt;
@use "./app/app/app.component.scss-theme" as act;
@use "./themes/default-theme" as defaultTheme;
@use "./themes/light-theme" as lightTheme;
@use "./themes/black-theme" as blackTheme;
@use "./themes/nature-theme" as natureTheme;

// Include Material core styles ONCE globally
@include mat.core();

// Apply typography globally if needed (once for all themes)
@include mat.all-component-typographies();

// Mixin for custom component theming
// @mixin custom-components-theme($theme) {
//   @include srt.oniks-styles-reset-theme($theme);
//   @include act.oniks-app-component-theme($theme);
// }

// Apply default theme
.default-theme {
  @include defaultTheme.theme(defaultTheme.$oniks-theme); // Apply color styles for default theme
}

// Apply light theme
.light-theme {
  // @include lightTheme.theme(lightTheme.$oniks-light-theme); // Apply color styles for light theme
}

// Apply black theme
.black-theme {
  @include blackTheme.theme(blackTheme.$oniks-black-theme); // Apply color styles for black theme
}

// Apply nature theme
// .nature-theme {
//   @include natureTheme.theme(
//     natureTheme.$oniks-nature-theme
//   ); // Apply color styles for nature theme
// }

div.mdc-notched-outline {
  height: 35px;
}

.mat-mdc-paginator-page-size {
  display: flex;
  align-items: center !important;
  margin-right: 8px;
}

.mat-mdc-text-field-wrapper {
  top: 15px;
}

/* Remove the padding from the paginator's select dropdown */
.mat-mdc-paginator .mat-mdc-select {
  padding-top: 5px; /* Override the padding for the paginator select */
}

/* Increase the height of the mat-form-field */
.mat-form-field {
  height: 56px; /* Adjust to the desired height */
}

/* Increase padding for the input */
.mat-input-element {
  padding: 10px; /* Increase padding around input to push it away from the label */
  height: auto; /* Ensure height auto-adjusts */
}

.mat-form-field {
  height: 80px; /* Increase overall height */
  margin-bottom: 16px; /* Add space below the form field */
}

.mat-input-element {
  padding: 12px; /* Add padding to the input element */
  font-size: 16px; /* Make input text larger */
}

label.mat-mdc-floating-label {
  top: -18px; /* Adjust the label to float higher */
  font-size: 14px; /* Increase label font size */
}
.mat-mdc-select {
  padding-top: 15px;
}

.mat-mdc-form-field {
  height: 64px;
}

.mat-mdc-form-field-input-control {
  margin-top: 15px;
}

:root {
  --mat-form-field-container-height: 45px; /* Set a custom container height */
}

.mat-mdc-form-field-error {
  margin-top: 8px; /* Add additional space between input and error message */
}

// Import Bootstrap
@import "bootstrap/scss/bootstrap";

// If you want to customize Bootstrap variables, add them before the import:
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);
