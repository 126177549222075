* {
  &:active,
  :focus {
    outline: none !important;
  }
}

code {
  white-space: nowrap;
  border-radius: 10px;
  padding: 0 8px 1px 8px;
}

.mdc-menu-content {
  fa-icon {
    position: relative;
    top: 2px;
    left: 4px;
  }
}

p {
  a {
    border-bottom: 3px solid;
  }
}
